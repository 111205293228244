import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "pages/MainLandingPage/Hero.js";
import Features from "pages/MainLandingPage/Features.js";
// import Testimonial from "pages/MainLandingPage/Testimonial.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <Features />
    {/*<Testimonial />*/}
  </AnimationRevealPage>
);
