import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription, FeatureTitle as FeatureTitleBase } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/Buttons.js";

import defaultCardImage from "images/patch-check.svg";
// import altCardImage from "images/ban.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/demo-more/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
// const Subheading = tw(SubheadingBase)`mb-4 text-primary-800 font-extrabold`;
const Heading = tw(SectionHeading)`w-full text-gray-700`;
const Description = tw(SectionDescription)`w-full max-w-full text-center text-gray-700`;
// const HighlightedText = tw.span`text-primary-700`;
const FeatureTitle = tw(FeatureTitleBase)`mt-0 w-full text-green-300`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-8 inline-block w-56 tracking-wide text-center py-5`;
const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xl`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-1 flex-shrink-0`}
    img {
      ${tw`w-12 h-12`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`tracking-wide font-bold text-xl text-primary-700 leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-gray-700 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
	                buttonRounded = true,
	                primaryButtonUrl = "https://calendly.com/empowert3",
	                primaryButtonText = "Schedule a Call",
	                cards = null,
	                heading = "Amazing Features",
	                subheading = "",
	                description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                }) => {
	
	const buttonRoundedCss = buttonRounded && tw`rounded-full`;
	
	const defaultCards = [
		{
			title: "Labelled Expenses and Income",
			description: "Gain deeper insights with custom tags for granular reporting."
		},
		{
			title: "Budgeting Made Easy",
			description: "Plan and track your finances with smart budgeting tools."
		},
		{
			title: "Streamline Payables and Receivables",
			description: "Manage incoming and outgoing funds efficiently."
		},
		{
			title: "Powerful Integrations",
			description: "Connect with essential tools you already use."
		},
		{
			title: "Simplified Data Entry",
			description: "Say goodbye to mountains of paperwork. Focus on your mission, not data entry."
		},
		{
			title: "Advanced Reporting and Customization",
			description: "Get the insights you need with robust, customizable reports."
		},
		{
			title: "Donation Tracking Made Easy",
			description: "Manage individual and recurring donations effortlessly."
		}
	];
	
	if (!cards) cards = defaultCards;
	
	return (
		<Container>
			<ThreeColumnContainer>
				{/*<Subheading>Other Features</Subheading>*/}
				<Heading>Upcoming Features Worth Knowing About</Heading>
				<Description w-full>Below is a highlight of ways we can help your organization. If you do not see a need listed, or have questions for us, please schedule a complimentary 30 minute exploratory session. We will talk through your needs and custom tailor a solution that works for you (and your budget)!</Description>
				<VerticalSpacer />
				{cards.map((card, i) => (
					<Column key={i}>
						<Card>
              <span className="imageContainer" style={{borderWidth : "0"}}>
                <img src={card.imageSrc || defaultCardImage} alt="check mark" />
              </span>
							<span className="textContainer">
                <FeatureTitle className="title">{card.title || "Fully Secure"}</FeatureTitle>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
						</Card>
					</Column>
				))}
				<VerticalSpacer />
				<PrimaryButton as="a" href="/contact" css={buttonRoundedCss}>
					{primaryButtonText}
				</PrimaryButton>
			</ThreeColumnContainer>
			<DecoratorBlob />
		</Container>
	);
};
