import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 ml-10 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold my-10`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Dive Software, LLC - Privacy Policy" }) => {
  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last Updated: January 31, 2024</p>
            <p>Version 1.0</p>
            
            <p>Dive Software, LLC ("Dive," "we," "us," "our") provides Software as a Service ("SaaS") fund accounting to our customers ("Customers"), and others who use the dive.fund website (the "Website"). This Privacy Policy (“Privacy Policy”) is designed to inform you about the types of information we collect, how we use it, and the choices you have regarding your information. Please read this policy carefully before using our software.</p>
            
            <h2>Information We Collect:</h2>
            
            <p><u>Account Information</u>: When you sign up for Dive, we collect information such as your name, email address, and organization details.</p>
            
            <p><u>Usage Information</u>: We collect information about how you interact with our software, including your IP address, device information, and the pages you visit.</p>
            
            <p><u>Billing Information</u>: If you choose to subscribe to our software, your billing and payment information is collected and retained by Stripe, our payment processor.</p>
            
            <h2>How We Use Your Information:</h2>
            
            <p><u>Providing Services</u>: We use your information to provide and improve our software, including customer support and technical assistance.</p>
            
            <p><u>Communication</u>: We may use your contact information to send you important updates, newsletters, or promotional materials. You can opt out of these communications at any time.</p>
            
            <p><u>Analytics</u>: We use analytics tools to understand how users interact with our software, helping us improve its functionality and user experience.</p>
            
            <p><u>Billing and Transactions</u>: We use your billing information to process payments and maintain your subscription.</p>
            
            
            <h2>Data Security:</h2>
            
            <p>We prioritize the security of your information and use industry-standard measures to protect against unauthorized access, disclosure, alteration, and destruction.</p>
            
            <p>Dive employs Firestore encryption and protocols to safeguard sensitive data during transmission.</p>
            
            
            <h2>Third-Party Services:</h2>
            <p>Our software utilizes third-party services such as Firebase for database management, and Stripe for payment processing. These services may have their own privacy policies, and we encourage you to review them.</p>
            
            
            <h2>Data Retention:</h2>
            <p>We retain your information for as long as necessary to provide our services and comply with legal obligations. You can request the deletion of your account and associated data at any time.</p>
            
            
            <h2>Your Choices:</h2>
            <p>You have the right to access, correct, or delete your personal information. Contact us at support@dive.fund for assistance.</p>
            <p>You can opt out of receiving promotional communications by following the unsubscribe instructions in the emails or contacting us.</p>
            
            
            <h2>Changes to this Privacy Policy:</h2>
            <p>We may update this Privacy Policy to reflect changes in our practices or for legal reasons. We will notify you of any significant changes.</p>
            
            
            <h2>Contact Us:</h2>
            
            <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at support@dive.fund.</p>
            <p>By using our software, you agree to the terms of this Privacy Policy.</p>
            <p>Dive, LLC</p>
            <p><a href="mailto:support@dive.fund" target="_blank" className="linkavitch">support@dive.fund</a></p>
          
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
