import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Pricing from "pages/Pricing/PriceBlocks.js";
// import Testimonial from "pages/Pricing/PricingTestimonials";
import FAQ from "components/FAQ.js";

export default () => {
  return (
    <AnimationRevealPage>
      <Pricing />
      {/*<Testimonial*/}
      {/*  heading="Our Paying Customers"*/}
      {/*/>*/}
      <FAQ />
    </AnimationRevealPage>
  );
};
