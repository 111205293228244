import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import 'styles/Dive.css'
import { css } from "styled-components/macro"; //eslint-disable-line

// import FAQ from "components/faqs/SimpleWithSideImage.js";
// import FAQ from "components/faqs/FAQ.js";
// import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";

/* Ready Made Pages (from demos folder) */
// import EventLandingPage from "demos/EventLandingPage.js";
// import HotelTravelLandingPage from "demos/HotelTravelLandingPage.js";
// import AgencyLandingPage from "demos/AgencyLandingPage.js";
// import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
// import RestaurantLandingPage from "demos/RestaurantLandingPage.js";
// import ServiceLandingPage from "demos/ServiceLandingPage.js";
// import HostingCloudLandingPage from "demos/HostingCloudLandingPage.js";

/* Inner Pages */
// import BlogIndexPage from "pages/BlogIndex.js";

import ComponentRenderer from "ComponentRenderer.js";

import Header from "./Header.js";

import MainLandingPage from "pages/MainLandingPage/MainLandingPage.js";
import AboutUsPage from "pages/AboutUs/AboutUs.js";
import ContactUsPage from "pages/ContactUs/ContactUs.js";
import DemoPage from "pages/Demo.js";
import LogInPage from "pages/Filler-Login.js";
import PricingPage from "pages/Pricing/Pricing.js";
import PrivacyPolicyPage from "pages/Legal/PrivacyPolicy.js";
import SignUpPage from "pages/Filler-Signup.js";
import TermsOfServicePage from "pages/Legal/TermsOfService.js";
import ThankYouPage from "pages/ThankYouPage.js";

import Footer from "./Footer.js";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <>
      <GlobalStyles />
      <Header />
      <Router>
        <Routes>
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/demo-yo" element={<DemoPage />} />
          <Route path="/login" element={<LogInPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/terms" element={<TermsOfServicePage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          
          <Route path="/" element={<MainLandingPage />} />
        </Routes>
      </Router>
      <Footer />
    </>
  );
}
