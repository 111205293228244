import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "components/misc/Headings.js";
import ImageExpertise from "images/Feature-13b-min.png";
import ImageAffordability from "images/Feature-12b-min.png";
import ImageUsability from "images/Feature-11-min.png";

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const Card = styled.div((props) => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row",
]);
const Container = tw.div`relative`;
const Content = tw.div`mt-16`;
const Image = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`,
]);
const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-gray-800`;
const Title = tw.h4`text-3xl font-bold text-gray-700`;
const Description = tw.p`mt-2 text-lg leading-loose text-primary-600`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon,
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon,
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon,
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon,
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc: ImageExpertise,
      // subtitle: "Paid",
      title: "Fund Accounting Expertise",
      description:
        "Ditch the spreadsheets and embrace a system crafted for non-profit fund and sub-fund management. Navigate complex accounting rules with ease, ensuring compliance and clarity. Stay stress-free and compliant with funder requirements, maximizing grant outcomes.",
      url: "https://timerse.com",
    },

    {
      imageSrc: ImageAffordability,
      // subtitle: "Free",
      title: "Built for Affordability",
      description:
        "We understand non-profit budgets. That's why our software offers powerful features without breaking the bank. Scale with your impact through flexible pricing, paying only for what you use. Never sacrifice features for affordability—we deliver industry-leading functionality at a fraction of the cost.",
      url: "https://timerse.com",
    },

    {
      imageSrc: ImageUsability,
      // subtitle: "Exclusive",
      title: "CPA-Designed Usability",
      description:
        "Forget clunky interfaces and steep learning curves. Our software is designed by a CPA who understands your challenges. Enjoy an intuitive experience that anyone can master quickly. Focus on your mission, not technology. Spend more time making a difference and less time battling software.",
      url: "https://timerse.com",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>What Makes Us Different</HeadingTitle>
          <HeadingDescription>
            Here's how we can take your nonprofit deeper.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
