import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
// import styled from "styled-components"; //eslint-disable-line
// import { css } from "styled-components/macro"; //eslint-disable-line
import MainFeature1 from "components/features/TwoColWithButton.js";
import SoftwareFeatures from "pages/AboutUs/SoftwareFeatures";
import ImageBankIntegration from "images/Feature-21-min.png";
import ImageBankReconciliation from "images/Feature-23b-min.png";
import ImageConnected from "images/Feature-04b-min.png";
import ImageReports from "images/Feature-05-min.png";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
// import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Seamless Bank Integration"
        description="Ditch the manual data entry grind. Connect your bank accounts and credit cards with Plaid, and watch your transactions automatically flow into your system. No more wasted hours uploading spreadsheets or transcribing receipts.
"
        buttonRounded={false}
        primaryButtonText="Try It Out"
        primaryButtonUrl = "https://app.dive.fund/signup"
        imageSrc={ImageBankIntegration}
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Effortless Bank Reconciliation"
        description="Reconciling the bank? Snooze button activated. Our software effortlessly matches up your bank statements with your records, highlighting any discrepancies for a quick review. Spend less time reconciling and more time making impactful decisions."
        buttonRounded={false}
        primaryButtonText="Try It Out"
        imageSrc={ImageBankReconciliation}
        textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Stay Connected"
        description="Manage your entire financial ecosystem in one place. Build a directory of key contacts, track communication records, and oversee all account activity with a comprehensive log. Never miss a beat or lose track of important details."
        buttonRounded={false}
        primaryButtonText="Try It Out"
        imageSrc={ImageConnected}
      />
      <MainFeature1
        subheading={<Subheading></Subheading>}
        heading="Fast, Clear Reports"
        description="Say goodbye to confusing spreadsheets and cryptic financial jargon. Generate simple, visually appealing reports in seconds, even if you're not an accounting whiz. Gain quick insights into your finances with straightforward income statements, balance sheets, and cash flow summaries."
        buttonRounded={false}
        primaryButtonText="Try It Out"
        imageSrc={ImageReports}
        textOnLeft={false}
      />
      <SoftwareFeatures />
      {/*<Features*/}
      {/*  subheading={<Subheading>Our Values</Subheading>}*/}
      {/*  heading="We follow these."*/}
      {/*  description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."*/}
      {/*  cards={[*/}
      {/*    {*/}
      {/*      imageSrc: SupportIconImage,*/}
      {/*      title: "24/7 Support",*/}
      {/*      description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      imageSrc: ShieldIconImage,*/}
      {/*      title: "Strong Teams",*/}
      {/*      description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"*/}
      {/*    },*/}
      {/*    {*/}
      {/*      imageSrc: CustomerLoveIconImage,*/}
      {/*      title: "Customer Satisfaction",*/}
      {/*      description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*  linkText=""*/}
      {/*/>*/}
      {/*<TeamCardGrid */}
      {/*  subheading={<Subheading>Our Team</Subheading>}*/}
      {/*/>*/}
    </AnimationRevealPage>
  );
};
