import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            
            <p>Last Updated: January 31, 2024</p>
            
            <h2>Dive Software, LLC</h2>
            <h1>Terms of Service</h1>
            
            <p>PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCEPTING ELECTRONICALLY, ACCESSING OR USING THE SERVICES, YOU AGREE TO THESE TERMS.</p>
            
            <p>These Terms of Service constitute an agreement (this "Agreement") by and between Dive Software, LLC, a California limited liability company (“Dive”) and the non-profit or other business entity executing this Agreement ("Customer"). Customer's use of and Dive’s provision of the Dive System (as defined below in Section 1.8) are governed by this Agreement.</p>
            
            <p>EACH PARTY ACKNOWLEDGES THAT IT HAS READ THIS AGREEMENT, UNDERSTANDS IT, AND AGREES TO BE BOUND BY ITS TERMS, AND THAT THE PERSON BINDING THE CUSTOMER HAS BEEN AUTHORIZED TO DO SO. THIS AGREEMENT SHALL CONTROL THE RIGHTS AND OBLIGATIONS OF THE PARTIES UNLESS AN INDIVIDUALLY NEGOTIATED SOFTWARE AS A SERVICE AGREEMENT IS AGREED UPON IN WRITING.</p>
            
            <h2>1. The System and Third Party Providers</h2>
            
            
            <p><b>1.1. Use of the System.</b> During the Term, Customer may access and use the System pursuant to these Terms of Service.</p>
            
            <p><b>1.2. Documentation.</b> Documentation means Dive standard manual related to use of the System.  Customer may reproduce and use the Documentation provided through the online support center solely as necessary to support Users' use of the System.  "User" means any company or individual who uses the System on Customer's behalf or through Customer's account or passwords, whether authorized or not or through an account created by Customer for the use of another.</p>
            
            <p><b>1.3. System Revisions.</b> Dive may revise System features and functions at any time, including without limitation by removing such features and functions. If any such revision to the System materially reduces features or functionality provided pursuant to an Order, Customer may within 30 days of notice of the revision terminate such Order, without cause, or terminate this Agreement without cause if such Order is the only one outstanding.</p>
            
            
            <h2>2. System Fees</h2>
            
            <p>Customer shall pay Dive the fee set forth in the Subscription Page the "Subscription Fee") for each Term (as defined in section 10.1 below). Dive will not be required to refund fees under any circumstances. Customer shall authorize regularly scheduled charges to your checking or savings account or credit card pursuant to the Subscription Page. Customer will be charged the amount indicated i or Subscription Page for each billing period. Customer agrees that no prior-notification will be provided unless the date or amount changes, in which case Customer will receive notice from Dive at least ten (10) days prior to the payment being collected. Dive reserves the right to charge for changes to the Subscription initiated by the customer during the term.</p>
            
            
            <h2>3. Customer Data & Privacy</h2>
            
            <p><b>3.1. Use of Customer Data.</b> Customer Data means data in electronic form input or collected through the System by or from Customer, including without limitation by Customer's Clients, by other Users, and by Customer's Clients' own customers. Unless it receives Customer's prior written consent, Dive: (a) shall not access, process, or otherwise use Customer Data other than as necessary to facilitate the System, provide Customer support, and to ascertain which features and products may be useful to Customer based on its usage; and (b) shall not intentionally grant any third party access to Customer Data, including without limitation Dive other customers, except subcontractors that are subject to a reasonable nondisclosure agreement. Notwithstanding the foregoing, Dive may disclose Customer Data as required by applicable law or by proper legal or governmental authority. Dive shall give Customer prompt notice of any such legal or governmental demand and reasonably cooperate with Customer in any effort to seek a protective order or otherwise to contest such required disclosure, at Customer's expense.  “Customer's Clients" means any of Customer's clients, members, donors, or customers or other third parties Customer gives access to the System, including without limitation such companies' agents and employees.</p>
            
            <p><b>3.2. Privacy Policy.</b> Privacy Policy means Dive privacy policy, currently posted at <a href="/privacy">dive.com/privacy</a>.
              The Privacy Policy applies only to the System and does not apply to any third party website or service linked to the System or recommended or referred to through the System or by Dive staff.</p>
            
            <p><b>3.3. Risk of Exposure.</b> Customer recognizes and agrees that hosting data online involves risks of unauthorized disclosure or exposure and that, in accessing and using the System, Customer assumes such risks. Dive offers no representation, warranty, or guarantee that Customer Data will not be exposed or disclosed through errors or the actions of third parties.</p>
            
            <p><b>3.4. Data Accuracy.</b> Dive shall have no responsibility or liability for the accuracy of data uploaded to the System by Customer, including without limitation Customer Data and any other data uploaded by Users.</p>
            
            <p><b>3.5. Data Deletion.</b> Dive may permanently erase Customer Data if Customer's account is delinquent, suspended, or terminated for thirty (30) days or more.</p>
            
            <p><b>3.6. Excluded Data.</b> Customer represents and warrants that Customer Data does not and will not include, and Customer has not and shall not upload or transmit to Dive computers or other media, any data ("Excluded Data"), which upon transmission would violate: HITECH/HIPAA, COPPA, FINRA, CAN-SPAM, California's Online Privacy Protection Act or any U.S. law or regulation that restricts data transmission; or any international privacy provision (the "Excluded Data Laws"). CUSTOMER RECOGNIZES AND AGREES THAT: (a) DIVE HAS NO LIABILITY FOR ANY FAILURE TO PROVIDE PROTECTIONS SET FORTH IN THE EXCLUDED DATA LAWS OR OTHERWISE TO PROTECT EXCLUDED DATA; AND (b) DIVE SYSTEMS ARE NOT INTENDED FOR MANAGEMENT OR PROTECTION OF EXCLUDED DATA AND MAY NOT PROVIDE ADEQUATE OR LEGALLY REQUIRED SECURITY FOR EXCLUDED DATA.</p>
            
            <p><b>3.7. Aggregate & De-Identified Data.</b> Notwithstanding the provisions above of this Article 4, Dive may use, reproduce, sell, publicize, or otherwise exploit Aggregate Data and De-Identified Data in any way, in its sole discretion. ("Aggregate Data" refers to summaries of Customer Data, or of data that includes Customer Data, that do not include personally identifiable information or the names or addresses of Customer and any of its Users. "De-Identified Data" refers to Customer Data with the following removed: personally identifiable information and the names and addresses of Customer and any of its Users).</p>
            
            
            <h2>4. Customer's Responsibilities & Restrictions</h2>
            
            <p><b>4.1. Acceptable Use.</b> Customer shall not: (a) use the System for service bureau or time-sharing purposes or in any other way allow third parties to exploit the System; (b) provide System passwords or other log-in information to any third party; (c) share non-public System features or content with any third party; (d) access the System in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics, or to copy any ideas, features, functions or graphics of the System; or (e) engage in web scraping or data scraping on or related to the System, including without limitation collection of information through any software that simulates human activity or any bot or web crawler. In the event that it suspects any breach of the requirements of this Section 4.1, including without limitation by Users, Dive may suspend Customer's access to the System without advanced notice, in addition to such other remedies as Dive may have. Neither this Agreement nor the AUP requires that Dive take any action against Customer or any User or other third party for violating the AUP, this Section 44.1, or this Agreement, but Dive is free to take any such action it sees fit.</p>
            
            <p><b>4.2. Unauthorized Access.</b> Customer shall take reasonable steps to prevent unauthorized access to the System, including without limitation by protecting its passwords and other log-in information. Customer shall notify Dive immediately of any known or suspected unauthorized use of the System or breach of its security and shall use best efforts to stop said breach.</p>
            
            <p><b>4.3. Compliance with Laws.</b> In its use of the System, Customer shall comply with all applicable laws, including without limitation laws governing the protection of personally identifiable information and other laws applicable to the protection of Customer Data.</p>
            
            <p><b>4.4. Customer's Users; System Access.</b> Customer is responsible and liable for: (a) Customer's Users' use of the System, including without limitation unauthorized User conduct and any User conduct that would violate the AUP or the requirements of this Agreement applicable to Customer; and (b) any use of the System through Customer's account, whether authorized or unauthorized.</p>
            
            
            <h2>5. IP & Feedback</h2>
            
            <p><b>5.1. IP Rights to the System.</b> Dive retains all right, title, and interest in and to the System, including without limitation all software used to provide the System and all graphics, user interfaces, logos, and trademarks reproduced through the System. This Agreement does not grant Customer any intellectual property license or rights in or to the System or any of its components. Customer recognizes that the System and its components are protected by copyright and other laws.</p>
            
            <p><b>5.2. Feedback.</b> Dive has not agreed to and does not agree to treat as confidential any Feedback (as defined below) Customer or Users provide to Dive, and nothing in this Agreement or in the parties' dealings arising out of or related to this Agreement will restrict Dive right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback, without compensating or crediting Customer or the User in question. Notwithstanding the provisions of Article 6  below, Feedback will not be considered Confidential Information, provided information Customer transmits with Feedback or related to Feedback may be considered Confidential Information. ("Feedback" refers to any suggestion or idea for improving or otherwise modifying any of Dive products or services).</p>
            
            
            <h2>6. Confidential Information</h2>
            
            <p>"Confidential Information" refers to the following items Dive discloses to Customer: (a) any document Dive marks "Confidential"; (b) any information Dive orally designates as "Confidential" at the time of disclosure, provided Dive confirms such designation in writing within five (5) business days; (c) the Documentation, whether or not marked or designated confidential; and (d) any other nonpublic, sensitive information Customer should reasonably consider a trade secret or otherwise confidential. Notwithstanding the foregoing, Confidential Information does not include information that: (i) is in Customer's possession at the time of disclosure; (ii) is independently developed by Customer without use of or reference to Confidential Information; (iii) becomes known publicly, before or after disclosure, other than as a result of Customer's improper action or inaction; or (iv) is approved for release in writing by Customer. Customer is on notice that the Confidential Information may include Dive valuable trade secrets.</p>
            
            <p><b>6.1. Nondisclosure.</b> Customer: (a) shall not disclose Confidential Information to any employee or contractor of Customer unless such person executes a nondisclosure agreement with Customer with terms no less restrictive than those of this Article 6; and (b) shall not disclose Confidential Information to any other third party without Dive prior written consent. Without limiting the generality of the foregoing, Customer shall protect Confidential Information with the same degree of care it uses to protect its own confidential information of similar nature and importance, but with no less than reasonable care. Customer shall promptly notify Dive of any misuse or misappropriation of Confidential Information that comes to Customer's attention. Notwithstanding the foregoing, Customer may disclose Confidential Information as required by applicable law or by proper legal or governmental authority. Customer shall give Dive prompt notice of any such legal or governmental demand and reasonably cooperate with Dive in any effort to seek a protective order or otherwise to contest such required disclosure, at Dive expense.</p>
            
            <p><b>6.2. Injunction.</b> Customer agrees that breach of this Article 6 would cause Dive irreparable injury, for which monetary damages would not provide adequate compensation, and that in addition to any other remedy, Dive will be entitled to injunctive relief against such breach or threatened breach, without proving actual damage or posting a bond or other security.</p>
            
            <p><b>6.3. Retention of Rights.</b> This Agreement does not transfer ownership of Confidential Information or grant a license thereto. Dive will retain all right, title, and interest in and to all Confidential Information.</p>
            
            <p><b>6.4. Exception & Immunity.</b> Pursuant to the Defend Trade Secrets Act of 2016, 18 USC Section 1833(b), Recipient is on notice and acknowledges that, notwithstanding the foregoing or any other provision of this Agreement:</p>
            
            <p><b>(a) Immunity.</b> An individual shall not be held criminally or civilly liable under any Federal or State trade secret law for the disclosure of a trade secret that- (A) is made- (i) in confidence to a Federal, State, or local government official, either directly or indirectly, or to an attorney; and (ii) solely for the purpose of reporting or investigating a suspected violation of law; or (B) is made in a complaint or other document filed in a lawsuit or other proceeding, if such filing is made under seal.</p>
            
            <p><b>(b) Use of Trade Secret Information in Anti-Retaliation Lawsuit.</b> An individual who files a lawsuit for retaliation by an employer for reporting a suspected violation of law may disclose the trade secret to the attorney of the individual and use the trade secret information in the court proceeding, if the individual- (A) files any document containing the trade secret under seal; and (B) does not disclose the trade secret.</p>
            
            
            <h2>7. Representations & Warranties</h2>
            
            <p><b>7.1. From Dive.</b> Dive represents and warrants that it is the owner of the System and of each and every component thereof, or the recipient of a valid license thereto, and that it has and will maintain the full power and authority to grant the rights granted in this Agreement without the further consent of any third party. Dive representations and warranties in the preceding sentence do not apply to use of the System in combination with hardware or software not provided by Dive. In the event of a breach of the warranty in this Section 7.1, Dive, at its own expense, shall promptly take the following actions: (a) secure for Customer the right to continue using the System; (b) replace or modify the System to make it noninfringing; or (c) terminate the infringing features of the Service and refund to Customer any prepaid fees for such features, in proportion to the portion of the Term left after such termination. In conjunction with Customer's right to terminate for breach where applicable, the preceding sentence states Dive sole obligation and liability, and Customer's sole remedy, for breach of the warranty in this Section 7.1 and for potential or actual intellectual property infringement by the System.</p>
            
            <p><b>7.2. From Customer.</b> Customer represents and warrants that: (a) it has the full right and authority to enter into, execute, and perform its obligations under this Agreement and that no pending or threatened claim or litigation known to it would have a material adverse impact on its ability to perform as required by this Agreement; (b) it has accurately identified itself and it has not provided any inaccurate information about itself to or through the System; and (c) it is a corporation, the sole proprietorship of an individual 18 years or older, or another entity authorized to do business pursuant to applicable law.</p>
            
            <p><b>7.3. Warranty Disclaimers.</b> Except to the extent set forth in Section 7.1 above, CUSTOMER ACCEPTS THE SYSTEM "AS IS" AND AS AVAILABLE, WITH NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE, COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING: (a) DIVE HAS NO OBLIGATION TO INDEMNIFY OR DEFEND CUSTOMER OR USERS AGAINST CLAIMS RELATED TO INFRINGEMENT OF INTELLECTUAL PROPERTY; (b) Dive DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM WILL PERFORM WITHOUT INTERRUPTION OR ERROR; AND (c) DIVE DOES NOT REPRESENT OR WARRANT THAT THE SYSTEM IS SECURE FROM HACKING OR OTHER UNAUTHORIZED INTRUSION OR THAT CUSTOMER DATA WILL REMAIN PRIVATE OR SECURE.</p>
            
            
            <h2>8. Indemnification</h2>
            
            <p>Customer shall defend, indemnify, and hold harmless Dive and the Dive Associates (as defined below) against any "Indemnified Claim," meaning any third party claim, suit, or proceeding arising out of or related to Customer's alleged or actual use of, misuse of, or failure to use the System, including without limitation: (a) claims by Users or by Customer's employees, as well as by Customer's own customers; (b) claims related to unauthorized disclosure or exposure of personally identifiable information or other private information, including Customer Data; (c) claims related to infringement or violation of a copyright, trademark, trade secret, or privacy or confidentiality right by written material, images, logos or other content uploaded to the System through Customer's account, including without limitation by Customer Data; and (d) claims that use of the System through Customer's account harasses, defames, or defrauds a third party or violates the CAN-Spam Act of 2003 or any other law or restriction on electronic advertising. Indemnified Claims include, without limitation, claims arising out of or related to Dive negligence. Customer's obligations set forth in this Article 8 include retention and payment of attorneys and payment of court costs, as well as settlement at Customer's expense and payment of judgments. Dive will have the right, not to be exercised unreasonably, to reject any settlement or compromise that requires that it admit wrongdoing or liability or subjects it to any ongoing affirmative obligations. (The "Dive Associates" are Dive officers, directors, shareholders, parents, subsidiaries, agents, successors, and assigns.)</p>
            
            
            <h2>9. Limitation of Liability</h2>
            
            <p><b>9.1. Dollar Cap.</b> DIVE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL NOT EXCEED THE AMOUNT OF FEES COLLECTED IN THE PRECEDING 6 (SIX) MONTHS.</p>
            
            <p><b>9.2. Exclusion of Consequential Damages.</b> IN NO EVENT WILL DIVE BE LIABLE TO CUSTOMER FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT.</p>
            
            <p><b>9.3. Clarifications & Disclaimers.</b> THE LIABILITIES LIMITED BY THIS ARTICLE 10 APPLY: (a) TO LIABILITY FOR NEGLIGENCE; (b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN IF DIVE IS ADVISED IN ADVANCE OF THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES WERE FORESEEABLE; AND (d) EVEN IF CUSTOMER'S REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. If applicable law limits the application of the provisions of this Article 9, Dive liability will be limited to the maximum extent permissible. For the avoidance of doubt, Dive liability limits and other rights set forth in this Article 10 apply likewise to Dive affiliates, licensors, suppliers, advertisers, agents, sponsors, directors, officers, employees, consultants, and other representatives.</p>
            
            
            <h2>10. Term</h2>
            
            <p><b>10.1. Term.</b> The term of this Agreement (the "Term") will commence on the date the Customer agrees to the (the "Effective Date") and continue for the period set forth on the Subscription Page ("Initial Term"). Thereafter, the Term will renew for successive periods of the same length of the Initial Term, unless either party refuses such renewal by written notice.</p>
            
            <p><b>10.2. Termination for Cause.</b> Either party may terminate this Agreement for the other's material breach by written notice specifying in detail the nature of the breach, effective in 30 days unless the other party first cures such breach, or effective immediately if the breach is not subject to cure.</p>
            
            <p>10.3. Effects of Termination. Upon termination of this Agreement, Customer shall cease all use of the System and delete, destroy, or return all copies of the Documentation in its possession or control. The following provisions will survive termination or expiration of this Agreement: (a) any obligation of Customer to pay fees incurred before termination; (b) Articles and Sections 5 (IP & Feedback), 6 (Confidential Information), 7.3 (Warranty Disclaimers), 8 (Indemnification), and 9 (Limitation of Liability); and (c) any other provision of this Agreement that must survive to fulfill its essential purpose.</p>
            
            
            <h2>11. Miscellaneous</h2>
            
            <p><b>11.1. Independent Contractors.</b> The parties are independent contractors and shall so represent themselves in all regards. Neither party is the agent of the other, and neither may make commitments on the other's behalf.</p>
            
            <p><b>11.2. Notices.</b> Dive may send notices pursuant to this Agreement to Customer's email contact points provided by Customer, and such notices will be deemed received 24 hours after they are sent. Customer may send notices pursuant to this Agreement to support@dive.fund and such notices will be deemed received 72 hours after they are sent.</p>
            
            <p><b>11.3. Force Majeure.</b> No delay, failure, or default, other than a failure to pay fees when due, will constitute a breach of this Agreement to the extent caused by acts of war, terrorism, hurricanes, earthquakes, other acts of God or of nature, strikes or other labor disputes, riots or other acts of civil disorder, embargoes, or other causes beyond the performing party's reasonable control.</p>
            
            <p><b>11.4. Assignment & Successors.</b> Customer may not assign this Agreement or any of its rights or obligations hereunder without Dive express written consent. Except to the extent forbidden in this Section 12.4, this Agreement will be binding upon and inure to the benefit of the parties' respective successors and assigns.</p>
            
            <p><b>11.5. Severability.</b> To the extent permitted by applicable law, the parties hereby waive any provision of law that would render any clause of this Agreement invalid or otherwise unenforceable in any respect. In the event that a provision of this Agreement is held to be invalid or otherwise unenforceable, such provision will be interpreted to fulfill its intended purpose to the maximum extent permitted by applicable law, and the remaining provisions of this Agreement will continue in full force and effect.</p>
            
            <p><b>11.6. No Waiver.</b> Neither party will be deemed to have waived any of its rights under this Agreement by lapse of time or by any statement or representation other than by an authorized representative in an explicit written waiver. No waiver of a breach of this Agreement will constitute a waiver of any other breach of this Agreement.</p>
            
            <p><b>11.7. Choice of Law & Arbitration.</b> This Agreement and all claims arising out of or related to this Agreement will be governed solely by the internal laws of the State of California, including without limitation applicable federal law, without reference to: (a) any conflicts of law principle that would apply the substantive laws of another jurisdiction to the parties' rights or duties; (b) the 1980 United Nations Convention on Contracts for the International Sale of Goods; or (c) other international laws. In the event of a dispute under this Agreement, the parties agree to arbitration under the Commercial Rules of the American Arbitration, which shall be held in Fresno, CA. This Section 11.7 governs all claims arising out of or related to this Agreement, including without limitation tort claims.</p>
            
            <p><b>11.8. Conflicts.</b> In the event of any conflict between this Agreement and any Dive policy posted online, including without limitation the AUP or Privacy Policy, the terms of this Agreement will govern.</p>
            
            <p><b>11.9. Technology Export.</b> Customer shall not: (a) permit any third party to access or use the System in violation of any U.S. law or regulation; or (b) export any software provided by Dive or otherwise remove it from the United States except in compliance with all applicable U.S. laws and regulations. Without limiting the generality of the foregoing, Customer shall not permit any third party to access or use the System in, or export such software to, a country subject to a United States embargo (as of the Effective Date, Cuba, Iran, North Korea, Sudan, and Syria).</p>
            
            <p><b>11.10. Entire Agreement.</b> This Agreement sets forth the entire agreement of the parties and supersedes all prior or contemporaneous writings, negotiations, and discussions with respect to its subject matter. Neither party has relied upon any such prior or contemporaneous communications.</p>
            
            <p><b>11.11. Amendment.</b> Dive may amend this Agreement from time to time by posting an amended version at its Website and sending Customer written notice thereof. Such amendment will be deemed accepted and become effective 30 days after such notice (the "Proposed Amendment Date") unless Customer first gives Dive written notice of rejection of the amendment. In the event of such rejection, this Agreement will continue under its original provisions, and the amendment will become effective at the start of Customer's next Term following the Proposed Amendment Date (unless Customer first terminates this Agreement pursuant to Article 10, Term & Termination). Customer's continued use of the Service following the effective date of an amendment will confirm Customer's consent thereto. This Agreement may not be amended in any other way except through a written agreement by authorized representatives of each party. Notwithstanding the foregoing provisions of this Section 11.11, Dive may revise the Privacy Policy and Acceptable Use Policy at any time by posting a new version of either at the Website, and such new version will become effective on the date it is posted.</p>
          
          </Text>
        </ContentWithPaddingXl>
      </Container>
    </AnimationRevealPage>
  );
};
